<template>
	<div>
		<b-card no-body>
			<b-card header-tag="header">
				<template v-slot:header>
					<product-form-nav link="options"  :productDetails="productDetails" :productId="id"></product-form-nav>
				</template>
				<form class="form" novalidate="novalidate" id="product_link">
					<b-card-text class="v-application">
						 <b-row class="ml-2">
							<b-col cols="8">
								<b-form-group>
									<label>Option Groups</label>
									<treeselect  v-model="form.selectedGroups" :multiple="true" :flat="true" :max-height="200" 
									:default-expand-level="1" :options="optionGroups" required :closeOnSelect="true" />
								</b-form-group>
							</b-col>
						</b-row>
						<b-row class="ml-2">

							<b-col cols="6" v-for="option in filteredOptionSelectList" :key="option.key">
								<b-form-group>
									<label>{{option.name}}</label>
									<treeselect  v-model="form.selectedOptions[option.key]" name="option" :max-height="200" 
									:default-expand-level="1" :options="option.list"   >
										<label slot="option-label" slot-scope="{ node }" >
											<img v-if="node.raw.image" class="img img-thumbnail" :src="imagePath+node.raw.image">{{ node.raw.label }}
										</label>
									</treeselect>
								</b-form-group>
							</b-col>
							
						</b-row>
						<b-row class="ml-2">
							<b-col lg="4" md="6">
								<b-form-group>
									<label>Quantity</label>
									<div class="position-relative">
										<b-form-input v-model="form.quantity"  name="quantity" type="number" placeholder=""></b-form-input>
									</div>
								</b-form-group>
							</b-col>
							<b-col lg="4" md="6">
								<b-form-group>
									<label>Price</label>
									<div class="position-relative">
										<b-form-input v-model="form.price" name="price" type="number" placeholder=""></b-form-input>
									</div>
								</b-form-group>
							</b-col>
							<b-col lg="4" md="6">
								<b-form-group>
									<label>Discount</label>
									<div class="position-relative">
										<b-form-input v-model="form.discount" type="number" placeholder=""></b-form-input>
									</div>
								</b-form-group>
							</b-col>

							<b-col lg="4" md="6">
								<b-form-group>
									<label>Start Date</label>
									<div class="position-relative">
										<Calendar v-model="form.startDate" dateFormat="yy-mm-dd" />
									</div>
								</b-form-group>
							</b-col>

							<b-col lg="4" md="6">
								<b-form-group>
									<label>End Date</label>
									<div class="position-relative">
										<Calendar v-model="form.endDate" dateFormat="yy-mm-dd" />
									</div>
								</b-form-group>
							</b-col>

							<b-col lg="4" md="6">
								<b-form-group>
									<label>Status</label>
									<div class="position-relative">
										<treeselect v-model="form.status"  :max-height="200" 
									:default-expand-level="1" :clearable="false" :options="statusOption" />
									</div>
								</b-form-group>
							</b-col>
							
						</b-row>

						<b-row>
							<b-col class="d-flex pb-4">
								<h4 class="m-0 pr-2">Dimensions</h4>
								<b-badge variant="primary" class="small">In MM</b-badge>
							</b-col>
						</b-row>
						<b-row class="ml-1">
							<b-col lg="3" md="6">
								<b-form-group>
									<label>Frame Width</label>
									<div class="position-relative">
										<i class="position-absolute right-0 top-0 mt-1 mr-2"><img src="/img/frame-width.svg"
												width="60" />
										</i>
										<b-form-input v-model="form.width" placeholder=""></b-form-input>
									</div>
								</b-form-group>
							</b-col>
							<b-col lg="3" md="6">
								<b-form-group>
									<label>Temple Length</label>
									<div class="position-relative">
										<i class="position-absolute right-0 top-0 mt-1 mr-2"><img
												src="/img/temple-length.svg" width="70" />
										</i>
										<b-form-input v-model="form.temple_length" placeholder=""></b-form-input>
									</div>
								</b-form-group>
							</b-col>
							<b-col lg="3" md="6">
								<b-form-group>
									<label>Bridge Width</label>
									<div class="position-relative">
										<i class="position-absolute right-0 top-0 mt-1 mr-2"><img
												src="/img/bridge-width.svg" width="70" />
										</i>
										<b-form-input v-model="form.bridge_width" placeholder=""></b-form-input>
									</div>
								</b-form-group>
							</b-col>

							<b-col lg="3" md="6">
								<b-form-group>
									<label>Lens Width</label>
									<div class="position-relative">
										<i class="position-absolute right-0 top-0 mt-1 mr-2"><img
												src="/img/lenswidth.png" width="70" />
										</i>
										<b-form-input v-model="form.lens_width" placeholder=""></b-form-input>
									</div>
								</b-form-group>
							</b-col>

							<b-col lg="3" md="6">
								<b-form-group>
									<label>Frame Height</label>
									<div class="position-relative">
										<i class="position-absolute right-0 top-0 mt-2 mr-2"><img src="/img/frameheight.svg"
												width="70" />
										</i>
										<b-form-input v-model="form.frame_height" placeholder=""></b-form-input>
									</div>
								</b-form-group>
							</b-col>
							<b-col lg="3" md="6">
								<b-form-group>
									<label>Frame Weight</label>
									<b-form-input v-model="form.frame_weight" placeholder=""></b-form-input>
								</b-form-group>
							</b-col>
						</b-row>
					
						<b-row>
							<b-col class="d-flex pb-4">
								<h4 class="m-0 pr-2">SEO</h4>
							</b-col>
						</b-row>
						<b-row>
							<b-col md="12">
								<b-form-group label-for="input-1">
								<label>Seo Title</label>
								<b-form-input
									v-model="form.seo_title"
									type="text"
									name="seo-title"
									class="form-control"
								>
								</b-form-input>
								</b-form-group>
							</b-col>
							<b-col md="12">
								<b-form-group class="fw-600">
								<label class="fw-600">Seo Content</label>
								<b-form-textarea
									v-model="form.seo_content"
									placeholder=""
									rows="3"
									max-rows="6"
								>
								</b-form-textarea>
								</b-form-group>
							</b-col>
						</b-row>
						<b-row>
							<b-col md="12">
								<b-form-group label-for="input-1">
								<label>Meta Title</label>
								<b-form-input
									v-model="form.meta_title"
									type="text"
									name="meta-title"
									class="form-control"
								>
								</b-form-input>
								</b-form-group>
							</b-col>
							<b-col md="12">
								<b-form-group class="fw-600">
								<label class="fw-600">Meta Discription</label>
								<b-form-textarea
									v-model="form.meta_description"
									placeholder=""
									rows="3"
									max-rows="6"
								>
								</b-form-textarea>
								</b-form-group>
							</b-col>
						</b-row>
						<b-row>
							<b-col class="text-right">
								<button class="btn btn-primary" ref="submit_option">
									<i class="las la-save"></i> Save and Continue
								</button>
							</b-col>
						</b-row>
					</b-card-text>
				</form>
			</b-card>
		</b-card>
	</div>
</template>

<script>
	import ProductFormNav from "./components/ProductFormNav.vue";
	import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

	// FormValidation plugins
	import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
	import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
	import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
	import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
	import KTUtil from "@/assets/js/components/util";
	import Calendar from 'primevue/calendar';

	import ProductService from "@/core/services/api/product/product";
	import OptionGroupService from "@/core/services/api/product/option";

	export default {
		components: {
			ProductFormNav,
			Calendar
		},
		data() {
			return {
				id: this.$route.params.id?parseInt(this.$route.params.id):null,
				vid: this.$route.params.vid?parseInt(this.$route.params.vid):null,
				 statusOption:[
                    {
                        id:"active",
                        label:"Active"
                    },
                    {
                        id:"inactive",
                        label:"InActive"
                    }
                ],
                selectedOptions:{},
				form:{
					selectedGroups:[],
					selectedOptions:{},
					quantity: 0,
					price: 0,
					discount: 0,
					endDate: "0000-00-00",
					startDate: this.moment().format('Y-MM-D'),
                    optionValues:[],
                    product_id: null,
                    status:"active",
					width:0,
					temple_length:0,
					bridge_width:0,
					frame_height:0,
					frame_weight:0,
					lens_width:0,
					seo_title:"",
					seo_content:"",
					meta_title:"",
					meta_description:""
				},
				productDetails:{},
				optionGroups:[],
				optionSelectList:[],
				imagePath:process.env.VUE_APP_API_STORAGE_URL
			};
		},
		computed: {
            filteredOptionSelectList: function () {
                var options = this.optionSelectList;
                var filteredList = [];
                for (const groupId of this.form.selectedGroups) {
                    filteredList.push(options.find((e)=>e.key == groupId))
                }
                return filteredList;
            },
        },
		created (){
			this.getOptionGroupList();
			if(this.vid)
				this.getVariantById();
			this.getProductById();
		},
		mounted() {
			this.$store.dispatch(SET_BREADCRUMB, [
				{
					title: "Dashboard",
					route: "/dashboard",
				},
				{
					title: "Products",
					route: "/products",
				},
				{
					title: "Manage Product : Data",
				},
			]);
			const signin_form = KTUtil.getById("product_link");
			this.fv = formValidation(signin_form, {
				fields: {
					quantity: {
						validators: {
							notEmpty: {
								message: "Product quantity is required",
							},
						},
					},
					price: {
						validators: {
							notEmpty: {
								message: "Product price is required",
							},
						},
					},
					options: {
						validators: {
							notEmpty: {
								message: "Product options is required",
							},
						},
					},
					option: {
						validators: {
							notEmpty: {
								message: "Product option is required",
							},
						},
					},
				},
				plugins: {
					trigger: new Trigger(),
					submitButton: new SubmitButton(),
					bootstrap: new Bootstrap(),
				},
			});
			
			this.fv.on("core.form.valid", () => {
				const submitButton = this.$refs["submit_option"];
				submitButton.classList.add("spinner", "spinner-light", "spinner-right","disabled");
				if(this.vid == null){
					this.createVariant(function(){
						submitButton.classList.remove(
							"spinner",
							"spinner-light",
							"spinner-right",
							"disabled"
						);
					});
				}else{
					this.updateVariant(function(){
						submitButton.classList.remove(
							"spinner",
							"spinner-light",
							"spinner-right",
							"disabled"
						);
					});
				}
			});
				
			
		},
		methods: {
			async getOptionGroupList() {
				var vm = this;
				var response = await OptionGroupService.getOptionGroupList();
				if(response){
					for(const group of response) {
						vm.optionGroups.push({
							id: group.id,
							label: group.name
						})
						var option = {
							key: group.id,
							name: group.name,
							list: [],
						}
						group.optionValues.map((e)=>{
							option.list.push({
								id: e.id,
								label: e.name,
								image: e.image
							})
						});

						vm.optionSelectList.push(option);
					}
					for (const gp of vm.optionGroups) {
						vm.selectedOptions[gp.id] = null;
					}

				}
			},
			async getProductById() {
				var response = await ProductService.getProductById(this.id);
				this.productDetails = response;
			},
			async getVariantById() {
				var vm = this;
				var response = await OptionGroupService.getVariantById(this.vid);
                this.form.price = response.price
                this.form.quantity = response.quantity
                this.form.discount = response.discount
                this.form.startDate = response.start_date
                this.form.endDate = response.end_date
                this.form.width = response.width
                this.form.temple_length = response.temple_length
                this.form.bridge_width = response.bridge_width
                this.form.frame_height = response.frame_height
                this.form.lens_width = response.lens_width
                this.form.frame_weight = response.frame_weight
                this.form.seo_title = response.seo_title
                this.form.seo_content = response.seo_content
				this.form.meta_title = response.meta_title
                this.form.meta_description = response.meta_description
                for (const opt of response.optionValues) {
                    if(this.form.selectedGroups.indexOf(opt.option_group_id) == -1)
                        this.form.selectedGroups.push(opt.option_group_id);
                    if(!this.form.selectedOptions.hasOwnProperty(opt.option_group_id))
                        this.form.selectedOptions[opt.option_group_id] = opt.option_value_id
                }
			},
			createVariant(callback){
                var vm = this;
                var optionValues = [];
                for (const opt of Object.values(vm.form.selectedOptions)) {
                    if(opt)
                        optionValues.push(opt)
                }
                vm.form.startDate = this.moment(vm.form.startDate).format('Y-MM-D')
                vm.form.endDate = this.moment(vm.form.endDate).format('Y-MM-D')
                vm.form.optionValues = optionValues;
                vm.form.product_id = vm.productDetails.id;
				OptionGroupService.createVariant(vm.form).then(function (response) {
					vm.$router.push({ name: "product-options", param: { id: vm.id } })
				}).catch(function (error){
					callback();
				});
            },
            updateVariant(callback){
                var vm = this;
                var optionValues = [];
                for (const opt of Object.values(vm.form.selectedOptions)) {
                    if(opt)
                        optionValues.push(opt)
                }
                vm.form.startDate = this.moment(vm.form.startDate).format('Y-MM-D')
                vm.form.endDate = this.moment(vm.form.endDate).format('Y-MM-D')
                vm.form.optionValues = [];
                vm.form.optionValues = optionValues;
                vm.form.product_id = vm.productDetails.id;
				OptionGroupService.updateVariant(vm.vid,vm.form).then(function (response) {
					vm.$router.push({ name: "product-options", param: { id: vm.id } })
				}).catch(function (error){
					callback();
				});
            },
		},
	};
</script>

<style></style>